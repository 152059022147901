import styled from "styled-components";
import DarkFilterImage from "../../components/basic/darkFilter";
import { Column } from "../../components/basic/box";
import { SubTitle, Title } from "../../components/basic/text";

const HeaderServices = ({ title, subTitle, img }) => {
  return (
    <ParentContainer>
      <DarkFilterImage minHeight={"800px"} src={img}>
        <Container>
          <TitleContainer>
            <CustomTitle data-aos="zoom-in-right" data-aos-duration="1000">
              {title}
            </CustomTitle>
            <CustomSubTitle data-aos="zoom-in-right" data-aos-duration="1000">
              {subTitle}
            </CustomSubTitle>
          </TitleContainer>
        </Container>
      </DarkFilterImage>
    </ParentContainer>
  );
};

const Container = styled(Column)`
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: clamp(700px, 100%, 1440px);
`;

const ParentContainer = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15%;
    background: ${({ theme }) => theme.colors.white};
  }

  @media screen and (max-width: 1900px) {
    &::after {
      content: "";
      height: calc(5% + 50px);
    }
  }

  @media screen and (max-width: 1000px) {
    &::after {
      content: "";
      height: 40px;
    }
  }

  @media screen and (max-width: 750px) {
    &::after {
      content: none;
    }
  }
`;


const TitleContainer = styled.div`
  /* background-color: red; */
  width: 100%;
`;

const CustomTitle = styled(Title)`
  font-size: clamp(2rem, 4.25vw, 3.5rem);
  /* margin-bottom: 300px; */
  margin-left: clamp(50px, 10%, 200px);
  /* white-space: pre; */
  text-align: left;
  text-shadow: rgba(0, 0, 0, 0.25) 0 4px 4px;
  width: 80%;
`;

const CustomSubTitle = styled(SubTitle)`
  /* margin-bottom: 300px; */
  font-size: clamp(1.4rem, 2.4vw, 1.6rem);
  margin-left: clamp(50px, 10%, 200px);
  text-align: left;
  text-shadow: rgba(0, 0, 0, 0.25) 0 4px 4px;
  font-family: ${({ theme }) => theme.font.belleza};
  font-weight: normal;
  width: 50%;
`;

export default HeaderServices;
