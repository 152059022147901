// TODO : faire les assetsd events
import EventBanner from "../assets/img/services/events/restauration.webp";
import EventFirst from "../assets/img/services/events/mariage.webp";
import EventSecond from "../assets/img/services/events/anniversaires.webp";
import EventThree from "../assets/img/services/events/hommes.webp";
import EventFour from "../assets/img/services/events/fleurs.webp";
import EventLastBanner from "../assets/img/services/events/banner.webp"; 
import styled from "styled-components";
import { Column, SizedBox } from "../components/basic/box";
import { BaseTitle } from "../components/basic/text";
import Button from "../components/basic/button";
import DarkFilterImage from "../components/basic/darkFilter";
import HeaderServices from "./services/HeaderServices";
import SplitViewComponent from "../components/splitViewComponent";
import { useNavigate } from "react-router-dom";

const EventServicesPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  // TODO : prendre décision naming
  const data = {
    title: "Événements",
    label:
      "L'Excellence à Chaque Instant",
    imageHeader: EventBanner,
    sectionsList: [
      {
        title: "Mariages de Rêve",
        text: "Votre union est une histoire d'amour unique. Confiez à Hortensia le soin d'orchestrer ce jour magique, enrichi d'une touche de luxe et de romantisme. Des cadres enchanteurs aux arrangements floraux délicats, en passant par une gastronomie exquise et des animations raffinées, nous créons une atmosphère féérique qui reflète votre amour unique.",
        img: EventFirst,
      },
      {
        title: "Anniversaires Mémorables",
        text: "Chaque anniversaire est un jalon précieux. Hortensia le célèbre avec éclat. Qu'il s'agisse d'une fête intime ou d'une grande célébration, chaque détail est méticuleusement soigné pour assurer un événement qui touche les cœurs et marque les esprits.",
        img: EventSecond,
      },
      {
        title: "Enterrements de Vie de Jeune Fille/Garçon",
        text: "Dites adieu à votre célibat avec éclat ! Nos escapades et soirées mémorables sont conçues pour garantir rire, divertissement et une touche de luxe inoubliable.",
        img: EventThree,
      },
      {
        title: "Organisation de Soirées Exclusives",
        text: "Pour une réception d'entreprise, une soirée caritative ou une célébration privée, Hortensia transforme votre événement en une soirée mémorable. Ambiances sur mesure, thèmes captivants et expériences interactives sont au programme pour engager et fasciner chaque invité.",
        img: EventFour,
      },
    ],
    imageBanner: {
      title:
        "N'hésitez plus à débuter la planification de votre événement de rêve. Ensemble, créons des instants non seulement célébrés, mais également chéris éternellement.",
      img: EventLastBanner,
    },
  };

  return (
    <Container>
      <HeaderServices
        title={data.title}
        subTitle={data.label}
        img={data.imageHeader}
      />
      {data.sectionsList.map((element, index) => {
        return (
          <SplitViewComponent
            key={index}
            title={element.title}
            text={element.text}
            reverse={index % 2 !== 0}
            imageUrl={element.img}
          />
        );
      })}
      <SizedBox height="100px" />
      <DarkFilterImage backdropFilterImage="blur(5px)" src={data.imageBanner.img} imageHeight="500px">
        <ContentContainer data-aos="fade-in" data-aos-duration="1500">
          <BannerTitle>{data.imageBanner.title}</BannerTitle>
          <Button
            text="Nous contacter"
            onClick={() => {
              handleClick();
            }}
          />
        </ContentContainer>
      </DarkFilterImage>
    </Container>
  );
};

const Container = styled.div`
  background-color: #eeeeee;
`;

const ContentContainer = styled(Column)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const BannerTitle = styled(BaseTitle)`
  font-size: clamp(1.6rem, 2.4vw, 2rem);
  text-align: center;
  width: 50%;
  min-width: 350px;
`;

export default EventServicesPage;
