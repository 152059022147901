import DarkFilterImage from "../basic/darkFilter";
import { Column, Row } from "../basic/box";
import Button from "../basic/button";
import MenuList from "./MenuList";
import { SubTitle, Title } from "../basic/text";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const ServicePresentation = ({ data, active }) => {
  const navigate = useNavigate();
  return (
    <DarkFilterImage  minHeight="850px" src={data.img}>
      <ColumnCustom active={active} >
        <CustomTitle data-aos="fade-down" data-aos-duration="1500">
          {data.title}
        </CustomTitle>
        <CustomSubTitle data-aos="fade-down" data-aos-duration="1500">
          {data.label}
        </CustomSubTitle>
        <CustomRow data-aos="fade-left" data-aos-duration="3000">
          <Button
            text="En savoir plus"
            onClick={() => {
              navigate(data.seeMoreLink);
              window.scrollTo(0, 0);
            }}
          />
        </CustomRow>
      </ColumnCustom>
    </DarkFilterImage>
  );
};

// const Container = styled.div`
//   min-height: 850px;
//   background-color: black;
// `;

const ColumnCustom = styled(Column)`
  width: 100%;
  /* height: clamp(700px, 70%, 1440px); */
  height: 75%;
  padding-right: clamp(60px, 5%, 150px);
  align-items: flex-end;
  justify-content: flex-start;
  z-index: ${({active}) => active ? 10000 : 1};
`;

const CustomTitle = styled(Title)`
  font-size: clamp(2rem, 4.25vw, 3rem);
  /* white-space: pre; */
  text-align: right;
  margin-bottom: 10px;
`;

const CustomSubTitle = styled(SubTitle)`
  color: ${({ theme }) => theme.colors.accent02};
  /* font-family: ${({ theme }) => theme.font.belleza}; */
  margin-top: 0;
  margin-bottom: 35px;
`;

const CustomRow = styled(Row)`
  /* margin-top: 20px; */
`;

export default ServicePresentation;
