const primaryTheme = {
  colors: {
    white: "#eeeeee",
    accent01: "#EFEBE7",
    accent02: "#B3A699",
    accent03: "#7E654D",
    accent04: "#574B3B",
    accent05: "#392817",
    accent11: "#EBDFD3",
    accent12: "#B8A695",
    accent13: "#664528",
    accent14: "#483525",
    accent15: "#200F00",
  },
  font: {
    belleza: "Belleza",
    catchyMager: "CatchyMager",
    sacramento: "Sacramento",
    bodoniFLF: "BodoniFLF",
  },
};

export { primaryTheme };
