import React from "react";
import styled from "styled-components";

const Bullets = ({ activeIndex, totalSlides, sliderRef  }) => {
  const handleBulletClick = (index) => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <BulletsContainer>
      {[...Array(totalSlides).keys()].map((index) => (
        <Bullet
          key={index}
          active={activeIndex === index}
          onClick={() => handleBulletClick(index)}
        />
      ))}
    </BulletsContainer>
  );
};

const BulletsContainer = styled.div`
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
  z-index: 10000;
`;

const Bullet = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.accent01 : "rgba(255, 255, 255, 0.5)"};
  margin: 0 4px;
  transition: transform 0.3s, opacity 0.3s;
  opacity: ${({ active }) => (active ? 1 : 0.7)};
  transform: ${({ active }) => (active ? "scale(1.25)" : "scale(1)")};
  cursor: pointer;
`;

export default Bullets;
