import HomeNavigation from "../navigation/HomeNavigation";

const Header = ({animation}) => {
  return (
    <header>
      <HomeNavigation enableAnimation={animation} />
    </header>
  );
};

export default Header;