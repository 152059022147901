import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg"; // Assurez-vous que le chemin est correct
import { useNavigate } from "react-router-dom";

const MobileMenu = ({ scrolled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const linkList = [
    { 
      title: "Événement", 
    src: "/event" },
    {
      title: "Entreprise",
      src: "/entreprise",
    },
    { title: "Contact", src: "/contact" },
  ];

  useEffect(() => {
    if (window.location.hash === "#service") {
      const serviceElement = document.getElementById("service");
      if (serviceElement) {
        serviceElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [window.location.hash]); // Se déclenche lorsque l'ancre dans l'URL change

  const handleClick = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
    setIsOpen(false);
  };

  return (
    <>
      <BurgerIcon
        scrolled={scrolled}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div />
        <div />
        <div />
      </BurgerIcon>

      <MenuContainer isOpen={isOpen}>
        <StyledLogo onClick={() => handleClick("/")} />
        <MenuItem
          onClick={() => {
            navigate("/");
            window.location.hash = "#service";
            setIsOpen(false);
          }}
        >
          Services
        </MenuItem>
        {linkList.map((item, index) => (
          <MenuItem key={index} onClick={() => handleClick(item.src)}>
            {item.title}
          </MenuItem>
        ))}
      </MenuContainer>
    </>
  );
};

const BurgerIcon = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 2000;
  display: none;
  justify-content: space-around;
  flex-flow: column nowrap;
  cursor: pointer;
  padding: 10px;
  border-radius: 100px;
  box-shadow: ${({ scrolled, isOpen }) =>
    scrolled && !isOpen ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none"};
  background: ${({ scrolled, theme }) =>
    scrolled ? theme.colors.accent01 : "rgba(255, 255, 255, 0.07)"};
  backdrop-filter: ${({ scrolled }) => (scrolled ? "none" : "blur(10px)")};
  transition: background 0.3s, backdrop-filter 0.3s;

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ theme, scrolled, isOpen }) =>
      !scrolled && !isOpen ? theme.colors.accent01 : theme.colors.accent15};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ isOpen }) =>
        isOpen ? "translateX(15%) rotate(45deg)" : "rotate(0)"};
    }

    &:nth-child(2) {
      transform: ${({ isOpen }) =>
        isOpen ? "translateX(100%)" : "translateX(0)"};
      opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ isOpen }) =>
        isOpen ? "translateX(15%) rotate(-45deg)" : "rotate(0)"};
    }
  }

  @media (max-width: 1020px) {
    display: flex;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.accent01};
  position: fixed;
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0%)" : "translateX(100%)"};
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  transition: transform 0.3s ease-in-out;
  z-index: 1500;
  padding: 3.5rem 0;

  @media (min-width: 1020px) {
    display: none;
  }
`;

const StyledLogo = styled(Logo)`
  position: relative;
  bottom: 25px;
  fill: ${({ theme }) => theme.colors.accent15};
  height: 35px;
  transition: transform 0.3s;
  &:hover {
    cursor: pointer;
  }
`;

const MenuItem = styled.div`
  font-family: ${({ theme }) => theme.font.belleza};
  font-size: 1.5rem;
  padding: 1rem 0;
  color: ${({ theme }) => theme.colors.accent15};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.accent14};
  }
`;

export default MobileMenu;
