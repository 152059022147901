import styled from "styled-components";

// . Base de textes

const BaseTitle = styled.h1`
  font-family: ${({ theme }) => theme.font.catchyMager};
  color: ${(props) =>
    props.secondary
      ? props.theme.colors.accent15
      : props.theme.colors.accent01};
  text-align: ${(props) => props.textAlign ?? "left"};
  /* margin: ${(props) => props.marginVertical ?? 0}px
    ${(props) => props.marginHorizontal ?? 0}px; */
`;

const BaseSubTitle = styled.h3`
  font-family: ${({ theme }) => theme.font.catchyMager};
  color: ${(props) =>
    props.secondary
      ? props.theme.colors.accent15
      : props.theme.colors.accent01};
  text-align: ${(props) => props.textAlign ?? "left"};
  /* margin: ${(props) => props.marginVertical ?? 0}px
    ${(props) => props.marginHorizontal ?? 0}px; */
`;

const BaseSpan = styled.span`
  font-family: ${({ theme }) => theme.font.belleza};
  color: ${(props) =>
    props.secondary
      ? props.theme.colors.accent15
      : props.theme.colors.accent01};
`;

const BaseText = styled.p`
  font-family: ${({ theme }) => theme.font.belleza};
  color: ${(props) =>
    props.secondary
      ? props.theme.colors.accent15
      : props.theme.colors.accent01};
  /* margin: ${(props) => props.marginVertical ?? 0}px
    ${(props) => props.marginHorizontal ?? 0}px; */
  font-size: clamp(1rem, 2.5vw, 1.2rem);
  text-align: ${(props) => props.textAlign ?? "left"};
`;

const BaseLink = styled.a`
  font-family: ${({ theme }) => theme.font.belleza};
  color: ${(props) =>
    props.secondary
      ? props.theme.colors.accent15
      : props.theme.colors.accent01};
  /* margin: ${(props) => props.marginVertical ?? 0}px
    ${(props) => props.marginHorizontal ?? 0}px; */
  font-size: clamp(1rem, 2.5vw, 1.2rem);
`;

const BaseLabel = styled.label`
  font-family: ${({ theme }) => theme.font.belleza};
  color: ${(props) =>
    props.secondary
      ? props.theme.colors.accent15
      : props.theme.colors.accent01};
  /* margin: ${(props) => props.marginVertical ?? 0}px
  ${(props) => props.marginHorizontal ?? 0}px; */
  font-size: clamp(1rem, 2.5vw, 1.2rem);
`;

const BaseList = styled.li`
  font-family: ${({ theme }) => theme.font.belleza};
  color: ${(props) =>
    props.secondary
      ? props.theme.colors.accent15
      : props.theme.colors.accent01};
  /* margin: ${(props) => props.marginVertical ?? 0}px
    ${(props) => props.marginHorizontal ?? 0}px; */
`;

const Title = styled(BaseTitle)`
  font-size: clamp(2rem, 4.25vw, 3rem);
  font-weight: 100;
  letter-spacing: 6px;
`;

const SubTitle = styled(BaseSubTitle)`
  font-size: clamp(1.6rem, 2.4vw, 2rem);
`;

export {
  Title,
  SubTitle,
  BaseSpan,
  BaseText,
  BaseList,
  BaseSubTitle,
  BaseLink,
  BaseLabel,
  BaseTitle,
};
