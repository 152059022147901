import React, { useRef, useState } from "react";
import styled from "styled-components";
import Button from "../components/basic/button";
import { BaseLabel, Title } from "../components/basic/text";
import Input from "../components/basic/input";
import * as emailjs from "@emailjs/browser";
import DarkFilterImage from "../components/basic/darkFilter";
import ContactImage from "../assets/img/contact/contact.webp";
import { ToastContainer, toast } from "react-toastify";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_pi1lzem",
        "template_v0av55w",
        form.current,
        "2xcaJztI8A8_ODzMu"
      )
      // TODO: remplacer les valeurs ci-dessus par les bonnes (PROD)
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Votre mail a bien été envoyé !", {
            duration: 4000,
            position: "top-center",
          });
        },
        (error) => {
          console.log(error.text);
          // TODO : afficher un message d'erreur
          toast.error("Une erreur est survenue", {
            duration: 4000,
            position: "top-center",
          });
        }
      );
  };

  return (
    <DarkFilterImage minHeight="900px" src={ContactImage}>
      <Container>
        <FormContainer>
          <StyledTitle>Contactez-nous</StyledTitle>
          <Form ref={form} onSubmit={sendEmail}>
            <FormGroup>
              <StyledLabel htmlFor="nom">Nom *</StyledLabel>
              <Input
                id="nom"
                name="nom"
                type="text"
                placeholder="Nom"
                required
              />
            </FormGroup>
            <FormGroup>
              <StyledLabel htmlFor="email">E-mail *</StyledLabel>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="E-mail"
                required
              />
            </FormGroup>
            <FormGroup>
              <StyledLabel htmlFor="phone">Numéro de téléphone</StyledLabel>
              <Input
                id="phone"
                name="phone"
                type="tel"
                placeholder="Numéro de téléphone"
              />
            </FormGroup>
            <FormGroup>
              <StyledLabel htmlFor="comment">Commentaire *</StyledLabel>
              <StyledTextarea
                id="comment"
                name="comment"
                placeholder="Commentaire"
                required
              />
            </FormGroup>
            <Button text="Envoyer" type="submit" />
          </Form>
        </FormContainer>
      </Container>
      <ToastContainer />
    </DarkFilterImage>
  );
};

const Container = styled.div`
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
`;

const FormContainer = styled.div`
  width: clamp(300px, 50%, 600px);
  margin: 0 auto;
  padding: 0 15px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 2rem;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const StyledLabel = styled(BaseLabel)`
  margin-bottom: 0.5rem;
  display: block;
`;

const StyledTextarea = styled.textarea`
  font-family: ${({ theme }) => theme.font.belleza};
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.accent05};
  border-radius: 6px;
  font-size: 1rem;
  margin: 5px 0;
  display: block;
  width: 100%;
  height: 150px;
  box-sizing: border-box;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent15};
  }
`;

export default ContactForm;
