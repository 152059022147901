import React from "react";
import styled from "styled-components";
import { Title, BaseText } from "../basic/text"; // Assurez-vous que ce chemin est correct.

const SplitViewComponent = ({ title, text, imageUrl, reverse }) => {
  const result = reverse ? ["left", "right"] : ["right", "left"];
  return (
    <SplitViewContainer reverse={reverse}>
      <ImageSection
        reverse={reverse}
        data-aos={"fade-" + result[0]}
        data-aos-duration="1500"
      >
        <StyledImage src={imageUrl} alt="Accommodation" />
      </ImageSection>
      <TextSection data-aos={"fade-" + result[1]} data-aos-duration="1500">
        {title && (
          <StyledTitle textalign={result[1]} secondary>
            {title}
          </StyledTitle>
        )}
        <StyledText textalign={result[1]} secondary>
          {text}
        </StyledText>
      </TextSection>
    </SplitViewContainer>
  );
};

const SplitViewContainer = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  align-items: stretch;
  justify-content: center;
  margin: auto;
  max-width: 1200px;
  gap: 20px;
  margin-top: 100px;

  @media (max-width: 868px) {
    flex-direction: column; // Gardez la colonne en mode téléphone.
  }
  @media (max-width: 1200px) {
    margin-left: 20px; // Gardez la colonne en mode téléphone.
    margin-right: 20px;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: ${({ reverse }) => (reverse ? "flex-end" : "flex-start")};
`;

const StyledImage = styled.img`
  width: 70%;
  height: auto;
  object-fit: cover;
`;

const TextSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
  text-align: ${({ textalign }) => textalign};
`;

const StyledText = styled(BaseText)`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
`;

export default SplitViewComponent;
