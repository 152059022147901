import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "../../components/basic/button";
import { BaseText, Title } from "../../components/basic/text";

const Error404Page = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <Title textAlign="center" secondary>Oops!</Title>
        <BaseText>Erreur 404 - Page non trouvée</BaseText>
        <ButtonContainer>
          <Button text="Retourner à l'accueil" onClick={() => navigate("/")} />
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default Error404Page;

const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  text-align: center;
  h1 {
    font-size: 3em;
    color: #333; // Couleur du titre
  }
  p {
    font-size: 1.5em;
    color: #666; // Couleur du texte
  }
`;
const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center; // Centre horizontalement
`;
