import styled from "styled-components";

const Column = styled.div`
  display: flex;
  flex-direction: ${(props) => props.reverse ? "column-reverse" : "column"};
  align-items: ${(props) => props.alignitems ?? "baseline"};
  justify-content: ${(props) => props.justifycontent ?? "flex-start"};
`;

const Row = styled.div`
  display: flex;
  flex-direction: ${(props) => props.reverse ? "row-reverse" : "row"};
  align-items: ${(props) => props.alignitems ?? "baseline"};
  justify-content: ${(props) => props.justifycontent ?? "flex-start"};
  gap: ${(props) => props.gap ?? 0};
`;

const SizedBox = styled.div`
  width: ${(props) => props.width ?? "0px"};
  height: ${(props) => props.height ?? "0px"};
`;

export { Column, Row, SizedBox };
