import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg"; // Assurez-vous que le chemin est correct
import { useEffect } from "react";

const TopNavbar = ({ scrolled }) => {
  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (window.location.hash === "#service") {
      const serviceElement = document.getElementById("service");
      if (serviceElement) {
        serviceElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [window.location.hash]); // Se déclenche lorsque l'ancre dans l'URL change

  const linkList = [
    {
      title: "Événement",
      src: "/event",
    },
    {
      title: "Entreprise",
      src: "/entreprise",
    },
    {
      title: "Contact",
      src: "/contact",
    },
  ];

  const listService = [
    {
      title: "Événement",
      src: "/event",
    },
    {
      title: "Entreprise",
      src: "/entreprise",
    },
    {
      title: "Contact",
      src: "/contact",
    },
  ];

  return (
    <Fixed>
      <StyledNavbar scrolled={scrolled}>
        <>
          <StyledLogo
            scrolled={scrolled}
            onClick={() => {
              handleClick("/");
            }}
          />
        </>
        <Left scrolled={scrolled}>
          {/* <DropdownMenu> */}
          <MenuContainer>
            <NavLinkService
              scrolled={scrolled}
              onClick={() => {
                navigate("/");
                window.location.hash = "#service";
              }}
              className="dropDownMenuHover"
            >
              Services
            </NavLinkService>
          </MenuContainer>
          {/* <DropdownContainer> */}
          {/* {listService.map((element, index) => {
                return (
                  <MenuContainer>
                    <NavLink
                      scrolled={scrolled}
                      key={index}
                      onClick={() => {
                        handleClick(element.src);
                      }}
                    >
                      {element.title}
                    </NavLink>
                  </MenuContainer>
                );
              })} */}
          {/* </DropdownContainer> */}
          {/* </DropdownMenu> */}
          {linkList.map((element, index) => {
            return (
              <MenuContainer>
                <NavLink
                  scrolled={scrolled}
                  key={index}
                  onClick={() => {
                    handleClick(element.src);
                  }}
                >
                  {element.title}
                </NavLink>
              </MenuContainer>
            );
          })}
        </Left>
      </StyledNavbar>
    </Fixed>
  );
};

const Fixed = styled.div`
  position: fixed;
  z-index: 1000;
  top: 30px;
  width: 100%;
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledNavbar = styled.nav`
  z-index: 1000;
  font-family: ${({ theme }) => theme.font.belleza};
  width: clamp(800px, 60vw, 900px);
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  box-shadow: ${({ scrolled }) =>
    scrolled ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "none"};
  background: ${({ scrolled, theme }) =>
    scrolled ? theme.colors.accent01 : "rgba(255, 255, 255, 0.07)"};
  backdrop-filter: ${({ scrolled }) => (scrolled ? "none" : "blur(10px)")};
  transition: background 0.3s, backdrop-filter 0.3s;
  border-radius: 15px;
`;

const fadeInSlideDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Left = styled.div`
  display: flex;
  gap: 1rem;

  position: relative;
`;

const NavLink = styled.span`
  font-size: 1.25rem;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  color: ${({ theme, scrolled }) =>
    scrolled ? theme.colors.accent15 : theme.colors.accent01};
`;

const StyledLogo = styled(Logo)`
  fill: ${({ theme, scrolled }) =>
    scrolled ? theme.colors.accent15 : theme.colors.accent01};
  height: 30px;
  position: relative;
  left: -30px;
  transition: transform 0.3s;
  &:hover {
    cursor: pointer;
  }
`;

const DropdownContainer = styled.div`
  background-color: black;
  position: absolute;
  border-radius: 10px;
  margin-top: 15px;
  display: none;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease display 0.3s ease;
  background: ${({ scrolled, theme }) =>
    scrolled ? theme.colors.accent01 : "rgba(255, 255, 255, 0.07)"};
  backdrop-filter: ${({ scrolled }) => (scrolled ? "none" : "blur(10px)")};

  /* background-color: red; */
`;

const DropdownMenu = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 100px; // Hauteur approximative du DropdownContainer
    pointer-events: none; // Assurez-vous qu'il ne bloque pas les interactions avec d'autres éléments
  }

  &:hover ${DropdownContainer} {
    display: block;
    animation: ${fadeInSlideDown} 0.3s ease forwards;
  }
`;

const MenuContainer = styled.div`
  ::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ theme, scrolled }) =>
      scrolled ? theme.colors.accent15 : theme.colors.accent01};
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.3s;
  }

  :hover::after {
    transform: scaleX(1);
  }
`;

const NavLinkService = styled(NavLink)`
  &:hover + ${DropdownContainer} {
    display: block; // Affiche le container lors du survol
    animation: ${fadeInSlideDown} 0.3s ease forwards;
  }
`;

export default TopNavbar;
