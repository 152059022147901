import React from "react";
import styled from "styled-components";
import { BaseList, BaseSubTitle, BaseText, Title } from "../basic/text";
import { SizedBox } from "../basic/box";

const IconTextList = ({ items }) => {
  const animation = ["left", "up", "up", "right"];
  return (
    <Container>
      <CustomSizedBox height="50px" />
      <CustomTitle data-aos="zoom-in" data-aos-duration="1500" secondary={true}>
        À propos
      </CustomTitle>
      <DescriptionContainer>
        <Text secondary>
          Spécialisée dans la création d'expériences sur mesure, notre
          conciergerie allie tradition et innovation pour offrir des services
          personnalisés à une clientèle exigeante. Nous nous engageons à fournir
          des solutions haut de gamme dans l'organisation d'événements, les
          voyages d'affaires, les activités de team building et les cadeaux
          d'entreprise. Notre équipe, experte et discrète, garantit l'excellence
          et l'efficacité dans chaque projet. Intégrée au sein d'un groupe
          leader, nous visons à dépasser les attentes de nos clients, avec un
          engagement constant envers la qualité et la satisfaction.
        </Text>
      </DescriptionContainer>
      <SizedBox height="50px" />

      <List>
        {items.map((item, index) => (
          <ListItem
            data-aos={"fade-" + animation[index]}
            data-aos-duration="1500"
            key={index}
          >
            {item.iconType === "svg" ? (
              <item.icon
                style={{ fill: "black", width: "clamp(50px, 5vw, 70px)" }}
              />
            ) : (
              <StyledImage src={item.icon} />
            )}
            {item.iconType}
            <ListTitle secondary={true}>{item.title}</ListTitle>
            <ListText secondary={true}>{item.text}</ListText>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.accent01};
  min-height: 800px;

  @media (max-width: 900px) {
    min-height: 1100px;
  }

  @media (max-width: 550px) {
    min-height: 1600px;
  }
`;

const CustomTitle = styled(Title)`
  font-family: ${({ theme }) => theme.font.belleza};
  /* font-weight: bold; */
`;

const List = styled.ul`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0;
  max-width: 1200px; // or your desired max width
  width: 100%;

  @media (max-width: 900px) {
    margin-top: 50px;
  }

  @media (max-width: 550px) {
    margin-top: 100px;
  }
`;

const DescriptionContainer = styled.div`
  max-width: 800px;
  width: 100%;

  @media (max-width: 900px) {
    width: 80%;
  }

  @media (max-width: 550px) {
    width: 90%;
  }
`;

const ListTitle = styled(BaseSubTitle)`
  font-size: clamp(1.4rem, 2vw, 1.8rem);
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  white-space: pre-line;
`;

const StyledImage = styled.img`
  width: clamp(50px, 5vw, 70px);
`;

const ListItem = styled(BaseList)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 20%; // Adjust the basis and grow/shrink factors as needed
  margin: 10px;
  text-align: center;

  @media (max-width: 900px) {
    flex-basis: 40%;
  }

  @media (max-width: 550px) {
    flex-basis: 100%;
  }
`;

const Text = styled(BaseText)`
  height: 100px;
  text-align: center;
`;

const ListText = styled(Text)`
  @media (max-width: 550px) {
    width: 80%;
  }
`;

const CustomSizedBox = styled(SizedBox)`
  display: none;

  @media (max-width: 550px) {
    display: block;
  }
`;

export default IconTextList;
