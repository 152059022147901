import Button from "../components/basic/button";
import DarkFilterImage from "../components/basic/darkFilter";
import HomeBanner from "../assets/img/home/costume-luxe.webp";
import { Column, Row, SizedBox } from "../components/basic/box";
import { Title } from "../components/basic/text";
import ServicesPresentationList from "../components/servicesPresentationList";
import IconTextList from "../components/iconTextList";
import { servicesList } from "../data/data_HomePage";
// . icons
import popo from "../assets/icons/etoile.png";
import yugio from "../assets/icons/parametres.png";
import cofre from "../assets/icons/coffre-fort.png";
import boubou from "../assets/icons/boussole.png";

const HomePage = () => {
  const items = [
    {
      icon: popo, // src/assets/icons/etoile.png
      iconType: "image",
      title: "Distinction et \nÉlégance",
      text: "À l'instar d'une étoile filante façonnant le ciel, notre conciergerie dessine des services d'exception, taillés pour s'ajuster parfaitement à vos désirs avec une excellence inégalée",
    },
    {
      icon: yugio, // replace with your icon path
      iconType: "image",
      title: "Polyvalence et \nExpertise",
      text: "Notre polyvalence est votre privilège : bénéficiez d'une gamme complète de services gérés par des experts dédiés à l'excellence de votre quotidien",
    },
    {
      // icon: Logo, // replace with your icon path
      icon: cofre,
      title: "Intimité et \nDiscrétion",
      iconType: "image",
      text: "Votre intimité est sacrée. Faites l'expérience de services exclusifs avec la garantie de notre discrétion absolue, encadrée par une éthique professionnelle inébranlable",
    },
    {
      // icon: cofre, // replace with your icon path
      icon: boubou,
      title: "Innovation et \nSingularité",
      iconType: "image",
      text: "Au cœur de notre philosophie, l'innovation guide nos services pour offrir une singularité qui fait toute la différence dans votre expérience de conciergerie",
    },
    // ... more items
  ];

  const scrollToService = () => {
    const serviceElement = document.getElementById('service'); // Assurez-vous que l'élément a un attribut id='service'
    if (serviceElement) {
      const servicePosition = serviceElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: servicePosition, behavior: 'smooth' });
    }
  };

  return (
    <>
      <DarkFilterImage minHeight="500px" src={HomeBanner}>
        <Column alignitems="center">
          <Title
            data-aos="fade-down"
            data-aos-duration="3000"
            textAlign="center"
          >
            Conciergerie d'exception
          </Title>
          <Row gap="35px" data-aos="fade-up" data-aos-duration="1500">
            <Button
              data-aos="fade-up"
              data-aos-duration="1500"
              outline
              text="Voir les services"
              onClick={scrollToService}
            />
          </Row>
        </Column>
      </DarkFilterImage>
      <SizedBox height="60px" />
      <IconTextList items={items} />
      <SizedBox height="60px" />
      <ServicesPresentationList
        list={servicesList}
        menu={servicesList.map((element) => element.title.replace("\n", " "))}
      />
    </>
  );
};

export default HomePage;
