// Input.js
import styled from "styled-components";

const Input = styled.input`
  font-family: ${({ theme }) => theme.font.belleza};
  color: ${({ theme }) => theme.colors.accent15};
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.accent02};
  /* background-color: ${({theme}) => theme.colors.accent01}; */
  border-radius: 6px;
  font-size: 1rem;
  margin: 5px 0;
  display: block;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.accent15};
  }
`;

export default Input;
