import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./index.css";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { primaryTheme } from "./config/theme";
import router from "./config/routes";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.primary};
    overflow-x: hidden;
  }

/* Styles par défaut des bullets */
.slick-dots li button:before {
  font-size: 10px; /* Contrôle la taille des bullets */
  color: ${({ theme }) =>
    theme.colors.accent01} !important; /* Couleur des bullets */
  opacity: 0.7; /* Opacité des bullets non-actifs */
  transition: transform 0.3s, opacity 0.3s; /* Transition pour le survol et le changement d'état */
}

/* Styles pour le bullet actif */
.slick-dots li.slick-active button:before {
  transform: scale(1.25); /* Agrandit le bullet actif */
  opacity: 1; /* Opacité du bullet actif */
}


`;

const App = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <ThemeProvider theme={primaryTheme}>
      <RouterProvider router={router} />
      <GlobalStyle></GlobalStyle>
    </ThemeProvider>
  );
};

export default App;
