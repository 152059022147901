import styled from "styled-components";
import React from "react";

const Button = ({ outline, onClick, text }) => {
  return (
    <StyledButton onClick={onClick} outline={outline}>
      {text}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  background-color: ${({ theme, outline }) => outline ? 'transparent' : theme.colors.accent12};
  padding: ${(props) => props.padding ?? "4px 20px"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: ${({ theme, outline }) => outline ? `2px solid ${theme.colors.accent01}` : 'none'};
  color: ${({ theme, outline }) => outline ? theme.colors.accent01 : theme.colors.accent05};
  margin: ${(props) => props.margin ?? 0};
  font-family: ${({ theme }) => theme.font.belleza};
  transition: background-color 0.3s, transform 0.3s;
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  cursor: pointer;
  backdrop-filter: ${({ outline }) => outline ? 'blur(10px)' : 'none'};
  &:hover {
    background-color: ${({ theme, outline }) => outline ? 'rgba(255, 255, 255, 0.2)' : theme.colors.accent11};
  }

`;

export default Button;
