// TODO : faire les assets event
import EntrepriseBanner from "../assets/img/services/entreprise/bureau.webp";
import EntrepriseFirst from "../assets/img/services/entreprise/seminaire.webp";
import EntrepriseSecond from "../assets/img/services/entreprise/team building.webp";
import EntrepriseThree from "../assets/img/services/entreprise/avion.webp";
import EntrepriseFour from "../assets/img/services/entreprise/cadeau.webp";
import EntrepriseLastBanner from "../assets/img/services/entreprise/seminaire.webp";
import styled from "styled-components";
import { Column, SizedBox } from "../components/basic/box";
import { BaseTitle } from "../components/basic/text";
import Button from "../components/basic/button";
import DarkFilterImage from "../components/basic/darkFilter";
import HeaderServices from "./services/HeaderServices";
import SplitViewComponent from "../components/splitViewComponent";
import { useNavigate } from "react-router-dom";

const EntrepriseServicesPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  // TODO : finir le copywriting
  const data = {
    title: "Entreprise",
    label:
      "Valoriser Chaque Instant Professionnel",
    imageHeader: EntrepriseBanner,
    sectionsList: [
      {
        title: "Séminaires Inoubliables",
        text: "Plus qu'une simple réunion, un séminaire Hortensia est une plongée dans un univers où chaque détail stimule la créativité, favorise la communication et inspire l'excellence. Des espaces inspirants, des équipements de pointe, une restauration raffinée et des moments de détente orchestrés pour favoriser la réflexion et la cohésion.",
        img: EntrepriseFirst,
      },
      {
        title: "Team Building Sur Mesure",
        text: "Essentiel au succès de toute entreprise, nos activités de team building sont conçues pour renforcer l'esprit d'équipe et révéler les talents individuels. Quelle que soit votre quête, une aventure excitante ou une expérience créative, nous créons des activités qui reflètent les valeurs et les objectifs de votre entreprise, tout en veillant à l'engagement de chaque participant.",
        img: EntrepriseSecond,
      },
      {
        title: "Voyages d'affaires",
        text: "Vos voyages d'affaires orchestrés par hortensia sont synonymes de confort et d'efficacité. Nous assurons une planification impeccable, de la sélection des vols à l'organisation de l'hébergement et des transports locaux, veillant à ce que chaque déplacement soit une expérience fluide et sans stress. Notre priorité est de vous permettre de vous concentrer sur l'essentiel : vos affaires.",
        img: EntrepriseThree,
      },
      {
        title: "Cadeaux d'entreprise et de bien-être",
        text: "Nous sublimons l'art de l'attention avec une sélection de cadeaux d'entreprise et de bien-être. Chaque présent est soigneusement choisi pour incarner l'esprit de votre marque et laisser une impression durable. Nos collections vont des objets personnalisés de haute qualité aux expériences de détente exclusives, assurant ainsi que chaque cadeau soit non seulement élégant mais aussi significatif.",
        img: EntrepriseFour,
      },
    ],
    imageBanner: {
      title:
        "Ensemble, élaborons un programme personnalisé qui non seulement répond à vos attentes professionnelles mais les transcende, en créant des moments à la fois enrichissants et mémorables pour toute votre équipe.",
      img: EntrepriseLastBanner,
    },
  };

  return (
    <Container>
      <HeaderServices
        title={data.title}
        subTitle={data.label}
        img={data.imageHeader}
      />
      {data.sectionsList.map((element, index) => {
        return (
          <SplitViewComponent
            key={index}
            title={element.title}
            text={element.text}
            reverse={index % 2 !== 0}
            imageUrl={element.img}
          />
        );
      })}
      <SizedBox height="100px" />
      <DarkFilterImage backdropFilterImage="blur(5px)" src={data.imageBanner.img} imageHeight="500px">
        <ContentContainer data-aos="fade-in" data-aos-duration="1500">
          <BannerTitle>{data.imageBanner.title}</BannerTitle>
          <Button
            text="Nous contacter"
            onClick={() => {
              handleClick();
            }}
          />
        </ContentContainer>
      </DarkFilterImage>
    </Container>
  );
};

const Container = styled.div`
  background-color: #eeeeee;
`;

const ContentContainer = styled(Column)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const BannerTitle = styled(BaseTitle)`
  font-size: clamp(1.6rem, 2.4vw, 2rem);
  text-align: center;
  width: 50%;
  min-width: 350px;
`;

export default EntrepriseServicesPage;
