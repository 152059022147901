import { createBrowserRouter } from "react-router-dom";
import Header from "../components/headers";
import Footer from "../components/footer";
import HomePage from "../pages/HomePage";
import Error404Page from "../pages/error/Error404";
import ServicesPage from "../pages/services/ServicesPage";
import ContactPage from "../pages/ContactPage";
import EntrepriseServicesPage from "../pages/EntrepriseServicesPage";
import EventServicesPage from "../pages/EventServicesPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Header />
        <HomePage />
        <Footer />
      </>
    ),
  },
  {
    path: "/services/voyage-deplacement",
    element: (
      <>
        <Header />
        <ServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/services/logement",
    element: (
      <>
        <Header />
        <ServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/services/restauration",
    element: (
      <>
        <Header />
        <ServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/services/transport",
    element: (
      <>
        <Header />
        <ServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/services/visites-excursions",
    element: (
      <>
        <Header />
        <ServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/services/bien-etre",
    element: (
      <>
        <Header />
        <ServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/services/yatching",
    element: (
      <>
        <Header />
        <ServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/services/extras",
    element: (
      <>
        <Header />
        <ServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/contact",
    element: (
      <>
        <Header animation={false} />
        <ContactPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/entreprise",
    element: (
      <>
        <Header />
        <EntrepriseServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/event",
    element: (
      <>
        <Header />
        <EventServicesPage />
        <Footer />
      </>
    ),
  },
  {
    path: "*",
    element: (
      <>
        <Header animation={false} />
        <Error404Page />
        <Footer />
      </>
    ),
  },
]);

export default router;
