import styled from "styled-components";
import { Column } from "../basic/box";
import { BaseSpan } from "../basic/text";

const MenuList = ({ menu, changeSlide, activeIndex }) => {
  return (
    <CustomColumn data-aos="fade-down" data-aos-duration="1500">
      {menu.map((element, index) => (
        <InteractiveText
          className={activeIndex === index ? "react-slick-active-menu" : ""}
          active={activeIndex === index}
          onClick={() => changeSlide(index)}
          key={index}
        >
          {index + 1}. {element}
        </InteractiveText>
      ))}
    </CustomColumn>
  );
};

const CustomColumn = styled(Column)`
  align-items: end;
  /* margin-top: 150px; */
  position: absolute;
  top: clamp(400px, 50vh, 500px);
  right:clamp(60px, 5%, 150px);
`;

const InteractiveText = styled(BaseSpan)`
  font-family: ${({ theme }) => theme.font.catchyMager};
  font-size: clamp(1.2rem, 2.5vw, 1.7rem);
  transition: font-size 0.3s;
  text-align: right;
  margin: 5px;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  z-index: ${({ active }) => (active ? 10000 : 1)};
  &:hover {
    font-size: clamp(1.6rem, 3.7vw, 2rem);
    cursor: pointer;
  }

  @media screen and (max-width: 1020px) {
    &:hover {
      font-size: clamp(1.2rem, 2.5vw, 1.7rem);
    }
  }
`;

export default MenuList;
