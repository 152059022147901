import React from "react";
import styled from "styled-components";
import TopNavbar from "./TopNavbar"; // Assurez-vous que le chemin est correct
import MobileMenu from "./MobileMenu"; // Assurez-vous que le chemin est correct
import { useEffect, useState } from "react";

const HomeNavigation = ({ enableAnimation = true, scrollThreshold = 600 }) => {
  const [scrolled, setScrolled] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    setScrolled(window.scrollY > scrollThreshold);
  };

  useEffect(() => {
    if (enableAnimation) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (!enableAnimation) {
        window.removeEventListener("scroll", handleScroll);
        setScrolled(true);
      }
    };
  }, [enableAnimation, scrollThreshold, handleScroll]);

  return (
    <NavigationContainer>
      <DesktopMenu>
        <TopNavbar scrolled={scrolled} />
      </DesktopMenu>
      <MobileMenuContainer>
        <MobileMenu scrolled={scrolled} />
      </MobileMenuContainer>
    </NavigationContainer>
  );
};

const NavigationContainer = styled.div`
  width: 100%;
`;

const DesktopMenu = styled.div`
  @media (max-width: 1020px) {
    display: none;
  }
`;

const MobileMenuContainer = styled.div`
  display: none;
  @media (max-width: 1020px) {
    display: block;
  }
`;

export default HomeNavigation;
