import styled from "styled-components";
import HeaderServices from "./HeaderServices";
import SplitViewComponent from "../../components/splitViewComponent";
import { Column, SizedBox } from "../../components/basic/box";
import { servicesList } from "../../data/data_HomePage";
import { useLocation, useNavigate } from "react-router-dom";
import DarkFilterImage from "../../components/basic/darkFilter";
import { BaseTitle } from "../../components/basic/text";
import Button from "../../components/basic/button";

// Page détail service
const ServicesPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const path = useLocation().pathname;
  const data = servicesList.filter(
    (element) => element.seeMoreLink === path
  )[0];

  return (
    <Container>
      <HeaderServices
        title={data.title}
        subTitle={data.details.label}
        img={data.details.imageHeader}
      />
      {data.details.sectionsList.map((element, index) => {
        return (
          <SplitViewComponent
            key={index}
            title={element.title}
            text={element.text}
            reverse={index % 2 !== 0}
            imageUrl={element.img}
          />
        );
      })}
      <SizedBox height="100px" />
      <DarkFilterImage backdropFilterImage="blur(5px)" src={data.details.imageBanner.img} imageHeight="500px">
        <ContentContainer data-aos="fade-in" data-aos-duration="1500">
          <BannerTitle>{data.details.imageBanner.title}</BannerTitle>
          <Button
            text="Nous contacter"
            onClick={() => {
              handleClick();
            }}
          />
        </ContentContainer>
      </DarkFilterImage>
    </Container>
  );
};

const Container = styled.div`
  background-color: #eeeeee;
`;

const ContentContainer = styled(Column)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const BannerTitle = styled(BaseTitle)`
  font-size: clamp(1.6rem, 2.4vw, 2rem);
  text-align: center;
  width: 50%;
  min-width: 350px;
`;

export default ServicesPage;
