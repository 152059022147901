// TODO :  Faire les images de voyages
import VoyageBanner from "../assets/img/home/voyages.webp";
import VoyageFirst from "../assets/img/services/voyages et deplacement/montagne.webp";
import VoyageSecond from "../assets/img/services/voyages et deplacement/maison neige.webp";
import VoyageThree from "../assets/img/services/voyages et deplacement/plage tropical.webp";
import VoyageFour from "../assets/img/services/voyages et deplacement/plage.webp";
import VoyageLastBanner from "../assets/img/services/voyages et deplacement/couple-enjoying-sunset-with-amazing-ocean-and-moun.webp";

import LogementBanner from "../assets/img/home/maison.webp";
import LogementFirst from "../assets/img/services/logements/logement 01.webp";
import LogementSecond from "../assets/img/services/logements/logement 02.webp";
import LogementThree from "../assets/img/services/logements/logement 03.webp";
import LogementFour from "../assets/img/services/logements/logement 04.webp";
import LogementLastBanner from "../assets/img/services/logements/logement 05.webp";

import RestaurationBanner from "../assets/img/home/restauration.webp";
import RestaurationFirst from "../assets/img/services/restauration/restauration 01.webp";
import RestaurationSecond from "../assets/img/services/restauration/restauration 02.webp";
import RestaurationThree from "../assets/img/services/restauration/restauration 03.webp";
import RestaurationFour from "../assets/img/services/restauration/restauration 04.webp";
import RestaurationLastBanner from "../assets/img/services/restauration/restauration 05.webp";

import TransportBanner from "../assets/img/home/voiture luxe.webp";
import TransportFirst from "../assets/img/services/transport/jet prive.webp";
import TransportSecond from "../assets/img/services/transport/avion de luxe.webp";
import TransportThree from "../assets/img/services/transport/maritime.webp";
import TransportFour from "../assets/img/services/transport/maritime 2.webp";
import TransportLastBanner from "../assets/img/services/transport/maritime 2.webp";

import VisitesBanner from "../assets/img/home/visites-et-excursions.webp";
import VisitesFirst from "../assets/img/services/visites et excursions/animaux.webp";
import VisitesSecond from "../assets/img/services/visites et excursions/decouverte.webp";
import VisitesThree from "../assets/img/services/visites et excursions/exploration.webp";
import VisitesFour from "../assets/img/services/visites et excursions/visite.webp";
import VisitesLastBanner from "../assets/img/services/visites et excursions/excursion banner.webp";

import BienEtreBanner from "../assets/img/home/bien_etre.webp"; 
import BienEtreFirst from "../assets/img/services/bien être/homme.webp";
import BienEtreSecond from "../assets/img/services/bien être/soins visage.webp";
import BienEtreThree from "../assets/img/services/bien être/relaxation.webp";
import BienEtreFour from "../assets/img/services/bien être/bien etre.webp";
import BienEtreLastBanner from "../assets/img/services/bien être/jacuzzi.webp";

import YatchingBanner from "../assets/img/home/yatching.webp";
import YatchingFirst from "../assets/img/services/yatching/bateau01.webp";
import YatchingSecond from "../assets/img/services/yatching/bateau02.webp";
import YatchingThree from "../assets/img/services/yatching/bateau03.webp";
import YatchingFour from "../assets/img/services/yatching/bateau04.webp";
import YatchingLastBanner from "../assets/img/services/yatching/bateaubasdepage.webp";

import ExtrasBanner from "../assets/img/home/extras.webp";
import ExtrasFirst from "../assets/img/services/extras/baby sitting.webp";
import ExtrasSecond from "../assets/img/services/extras/gastronomie.webp";
import ExtrasThree from "../assets/img/services/extras/fleurs.webp";
import ExtrasFour from "../assets/img/services/extras/femme.webp";
import ExtrasLastBanner from "../assets/img/services/extras/gastronomie.webp";

export const servicesList = [
  {
    // . MODELE OBJET acements",
    title: "Voyages & Déplacements",
    label: "Destinations Exclusives",
    img: VoyageBanner,
    seeMoreLink: "/services/voyage-deplacement",
    details: {
      label: "Des adresses inédites dans un texte exceptionnel",
      imageHeader: VoyageBanner,
      sectionsList: [
        {
          title: "Évasion de Prestige",
          text: "Raffinez votre expérience de voyage avec des destinations exclusives et un service sur mesure. Chaque escapade est une évasion de prestige, conçue pour vous offrir une expérience unique et mémorable.",
          img: VoyageFirst,
        },
        {
          title: "Voyages Personnalisés",
          text: "Chaque destination devient une aventure unique, façonnée selon vos désirs. Nous créons des voyages personnalisés qui reflètent vos goûts et vos aspirations pour des moments inoubliables.",
          img: VoyageSecond,
        },
        {
          title: "Un Voyage Sur Mesure",
          text: "Plongez dans un monde de détails raffinés, où le luxe rencontre l'aventure. Nous vous invitons à découvrir des destinations extraordinaires et des hébergements d'exception, orchestrés pour créer vos souvenirs les plus précieux.",
          img: VoyageThree,
        },
        {
          title: "Réalisez Vos Rêves de Voyages",
          text: "Le voyage est une évasion du quotidien, offrant confort et découverte. Choisissez parmi des adresses uniques et vivez des expériences précieuses, que ce soit pour une escapade romantique ou une aventure familiale mémorable.",
          img: VoyageFour,
        },
      ],
      imageBanner: {
        title:
          "Explorez le summum de l'évasion luxueuse, et vivez des voyages sur mesure inoubliables.",
        img: VoyageLastBanner,
      },
    },
  },
  {
    title: "Logements",
    label: "Séjours de Prestige",
    img: LogementBanner,
    seeMoreLink: "/services/logement",
    details: {
      label: "Des Partenariats d'Excellence pour Votre Séjour Inoubliable",
      imageHeader: LogementBanner,
      sectionsList: [
        {
          title: "Sélection Prestigieuse",
          text: "Explorez notre sélection prestigieuse d'hébergements, offrant un confort exceptionnel et un service de haut standing pour un séjour inoubliable.",
          img: LogementFirst,
        },
        {
          title: "Élégance et Confort",
          text: "Votre séjour se transforme en une expérience élégante et confortable. Découvrez des hébergements où raffinement et sérénité se conjuguent pour une expérience unique.",
          img: LogementSecond,
        },
        {
          title: "Logements Exceptionnels",
          text: "Que vous cherchiez une escapade romantique ou une aventure urbaine, nos partenaires garantissent un service exceptionnel et des équipements de classe mondiale, pour un séjour parfait.",
          img: LogementThree,
        },
        {
          title: "Perfection de Votre Séjour",
          text: "Notre équipe de conciergerie s'efforce de rendre votre séjour parfait, qu'il s'agisse de détente dans un cadre enchanteur ou d'exploration urbaine. Chaque détail est pensé pour votre bien-être.",
          img: LogementFour,
        },
      ],
      imageBanner: {
        title:
          "Profitez de séjours exclusifs et prestigieux, et créez des expériences d'hébergement inoubliables.",
        img: LogementLastBanner,
      },
    },
  },
  {
    title: "Restauration",
    label: "Cuisine d'Exception",
    img: RestaurationBanner,
    seeMoreLink: "/services/restauration",
    details: {
      label:
        "Vivez une expérience culinaire exceptionnelle, alliant créativité gastronomique et saveurs exquises.",
      imageHeader: RestaurationBanner,
      sectionsList: [
        {
          title: "Évasion Gastronomique",
          text: "Entrez dans un monde de saveurs où chaque plat raconte une histoire de raffinement et d'innovation. Notre évasion gastronomique promet des moments gourmands mémorables.",
          img: RestaurationFirst,
        },
        {
          title: "Quête du Raffinement",
          text: "Redécouvrez le monde de la haute cuisine, où chaque expérience est une célébration de la gastronomie. Que vous soyez un amateur de vins fins ou un passionné de cuisine, nous promettons de ravir vos papilles.",
          img: RestaurationSecond,
        },
        {
          title: "Plaisirs Étoilés",
          text: "Explorez des restaurants étoilés au Michelin et des dégustations de vins exclusives. Chaque repas est une aventure dans l'art culinaire, promettant une expérience gastronomique sans pareil.",
          img: RestaurationThree,
        },
        {
          title: "Élégance Gastronomique",
          text: "Réservez votre table dans les meilleurs établissements et vivez des moments inoubliables. Notre monde de restauration de prestige est une invitation à savourer la vie dans toute sa splendeur.",
          img: RestaurationFour,
        },
      ],
      imageBanner: {
        title:
          "Découvrez un monde de saveurs raffinées et vivez des expériences gastronomiques exceptionnelles.",
        img: RestaurationLastBanner,
      } /* je te baise :) */,
    },
  },
  {
    title: "Transport",
    label: "Déplacements de Prestige",
    img: TransportBanner,
    seeMoreLink: "/services/transport",
    details: {
      label:
        "Découvrez le summum du voyage de prestige avec nos services de carrosses et jets privés, alliant luxe et confort.",
      imageHeader: TransportBanner,
      sectionsList: [
        {
          title: "Luxe Aérien",
          text: "Voyagez avec élégance et prestige grâce à notre service de conciergerie Carrosse et Jet Privé. Que ce soit dans les airs, sur terre ou en mer, nous vous offrons une expérience de voyage qui dépasse toutes vos attentes.",
          img: TransportFirst,
        },
        {
          title: "Confort en Altitude",
          text: "Embarquez à bord de jets privés luxueux, où le confort, la discrétion et l'exclusivité sont au rendez-vous. Vous pouvez également choisir de vous déplacer en limousine, avec un chauffeur professionnel qui vous mènera à votre destination dans le plus grand style. Et pour ceux qui préfèrent les voyages en mer, nos yachts de prestige offrent une expérience maritime incomparable.",
          img: TransportSecond,
        },
        {
          title: "Évasion Maritime",
          text: "Laissez-vous séduire par l'idée de voyager dans une voute céleste bleue, un cadre idyllique sur des chevaux majestueux, ou un jet privé qui vous emmènera vers des destinations de rêve. Quelle que soit votre préférence, notre équipe dévouée est là pour orchestrer chaque détail de votre voyage.",
          img: TransportThree,
        },
        {
          title: "Voyage Élégant",
          text: "Explorez notre flotte de jets privés, de limousines de prestige et de yachts exclusifs. Choisissez la manière de vous sous laquelle voyager, offrez-vous une expérience de transport qui allie confort, élégance et sophistication. Bienvenue dans le monde du voyage de prestige, où chaque déplacement devient une aventure exceptionnelle.",
          img: TransportFour,
        },
      ],
      imageBanner: {
        title:
          "Découvrez nos moyens de transport d'exception, offrez-vous une expérience de déplacement unique, et créez des souvenirs inoubliables.",
        img: TransportLastBanner,
      },
    },
  },
  {
    title: "Visites & Excursions",
    label: "Expériences Uniques",
    img: VisitesBanner,
    seeMoreLink: "/services/visites-excursions",
    details: {
      label:
        "Partez à la découverte de panoramas époustouflants et d'expériences uniques lors de nos visites et excursions sur mesure",
      imageHeader: VisitesBanner,
      sectionsList: [
        {
          title: "Découvertes et Panoramas",
          text: "Évadez-vous dans des lieux hors du commun avec nos visites et excursions sur mesure. Chaque itinéraire est une promesse d'enrichissement et de découverte, conçu pour ceux qui cherchent plus qu'un simple voyage, mais une véritable aventure culturelle et visuelle.",
          img: VisitesFirst,
        },
        {
          title: "Échappées Belles",
          text: "Partez à la conquête de paysages époustouflants et de secrets bien gardés avec nos excursions exclusives. Nous vous transportons au cœur de l'aventure, pour des souvenirs qui défient l'ordinaire et enrichissent votre répertoire de voyageur.",
          img: VisitesSecond,
        },
        {
          title: "Horizons Lointains",
          text: "Nos visites sont des portes ouvertes vers des horizons lointains, où chaque détour est une nouvelle page d'histoire à explorer. Rejoignez-nous pour des excursions qui sont autant de voyages dans le temps et l'espace, vers des destinations qui marquent les esprits.",
          img: VisitesThree,
        },
        {
          title: "Trésors Cachés",
          text: "Découvrez avec nous les trésors cachés du monde. Nos excursions vous mèneront vers des merveilles insoupçonnées, où chaque pas révèle une histoire, chaque lieu une émotion. Laissez-vous guider vers l'inattendu et l'extraordinaire, pour une expérience de visite inoubliable.",
          img: VisitesFour,
        },
      ],
      imageBanner: {
        title:
          "Découvrez nos circuits d'exception, offrez-vous des excursions uniques, et créez des souvenirs inoubliables.",
        img: VisitesLastBanner,
      },
    },
  },
  {
    title: "Bien-être",
    label: "Harmonie et Relaxation",
    img: BienEtreBanner,
    seeMoreLink: "/services/bien-etre",
    details: {
      label:
        "Immergez-vous dans un univers de relaxation et de bien-être avec nos programmes personnalisés et soins exclusifs",
      imageHeader: BienEtreBanner,
      sectionsList: [
        {
          title: "Sérénité et Plénitude",
          text: "Laissez le tumulte du quotidien derrière vous et plongez dans un univers de bien-être. Nos séjours bien-être sont conçus pour revitaliser le corps et l'esprit, avec des soins personnalisés qui vous transportent vers une tranquillité absolue.",
          img: BienEtreFirst,
        },
        {
          title: "Évasion Sensorielle",
          text: "Redécouvrez l'harmonie avec nos expériences de bien-être exclusives. Des massages aux aromes subtils aux sessions de méditation en pleine nature, chaque instant est une invitation à l'équilibre et à la paix intérieure.",
          img: BienEtreSecond,
        },
        {
          title: "Harmonie et Équilibre",
          text: "Atteignez un état de bien-être optimal avec nos retraites conçues sur mesure. Des programmes de détente aux thérapies innovantes, nous vous guidons vers un bien-être durable et profond.",
          img: BienEtreThree,
        },
        {
          title: "Retraite de Ressourcement",
          text: "Offrez-vous une pause de ressourcement dans nos havres de paix. Nos retraites bien-être associent environnements enchanteurs et soins d'exception pour une expérience ressourçante unique.",
          img: BienEtreFour,
        },
      ],
      imageBanner: {
        title:
          "Découvrez nos retraites bien-être d'exception, offrez-vous une expérience de relaxation sans pareil, et créez des souvenirs inoubliables.",
        img: BienEtreLastBanner,
      },
    },
  },
  {
    title: "Yatching",
    label: "Naviguez vers l'Évasion Totale",
    img: YatchingBanner,
    seeMoreLink: "/services/yatching",
    details: {
      label:
        "Naviguez vers l'exclusivité et le raffinement avec nos voiliers sur mesure et croisières uniques, où luxe et aventure se rencontrent pour votre plaisir.",
      imageHeader: YatchingBanner,
      sectionsList: [
        {
          title: "Un Embarquement d'Exception",
          text: "Embarquez pour un voyage inoubliable à bord de yachts somptueux et de voiliers féériques. Avec notre service personnalisé, vous vivrez une expérience maritime exceptionnelle, combinant luxe, confort et aventure.",
          img: YatchingFirst,
        },
        {
          title: "Des Destinations Inoubliables",
          text: "Explorez les eaux cristallines de destinations exotiques ou détendez-vous dans le cadre enchanteur de votre choix. Que vous soyez un passionné de croisière ou simplement en quête d'une escapade maritime mémorable, notre équipe de conciergerie met tout en œuvre pour répondre à vos besoins.",
          img: YatchingSecond,
        },
        {
          title: "Croisières Personnalisées de Qualité",
          text: "Optez pour des croisières exclusives, conçues sur mesure pour vous offrir des moments inoubliables en mer. Découvrez des itinéraires uniques, des activités captivantes et des services haut de gamme qui font de chaque voyage une aventure de luxe.",
          img: YatchingThree,
        },
        {
          title: "L'Océan, Entre Élégance et Aventure",
          text: "Explorez l'océan avec élégance et aventure. Nos croisières sur voiliers offrent une échappée belle où confort et découverte se conjuguent. Vivez des moments uniques, en harmonie avec la mer, tout en bénéficiant d'une attention exceptionnelle",
          img: YatchingFour,
        },
      ],
      imageBanner: {
        title:
          "Pour concrétiser votre prochaine aventure en mer, contactez-nous dès aujourd'hui. Notre équipe dédiée est prête à vous aider à réaliser vos rêves maritimes",
        img: YatchingLastBanner,
      },
    },
  },
  {
    title: "Services Extras",
    label: "Prestations Exclusives",
    img: ExtrasBanner,
    seeMoreLink: "/services/extras",
    details: {
      label:
        "Profitez d'une gamme de services extras, conçus pour enrichir votre séjour avec élégance, confort et sophistication.",
      imageHeader: ExtrasBanner,
      sectionsList: [
        {
          title: "Garde d'Enfants Expertise",
          text: "Voyagez l'esprit tranquille tandis que nos baby-sitters qualifiés veillent sur vos petits trésors. Avec une attention particulière portée à la sécurité et au divertissement, nous assurons un service de garde d'enfants fiable et ludique pour que chaque membre de la famille profite pleinement de son séjour.",
          img: ExtrasFirst,
        },
        {
          title: "Délices Gastronomiques",
          text: "Succombez aux plaisirs d'une table soigneusement dressée par nos chefs. Des ingrédients locaux aux créations culinaires avant-gardistes, chaque repas est une célébration du goût et de l'excellence, transformant vos dîners en moments d'exception.",
          img: ExtrasSecond,
        },
        {
          title: "Créations Florales",
          text: "Émerveillez-vous devant l'art floral sublimé par nos designers talentueux. De la sélection minutieuse des fleurs à la composition de bouquets personnalisés, nous apportons une touche de fraîcheur et d'élégance à votre environnement, pour éveiller vos sens et embellir vos espaces.",
          img: ExtrasThree,
        },
        {
          title: "Service Majordome",
          text: "Profitez d'un service impeccable avec nos majordomes dévoués. Présents pour répondre à chacun de vos besoins, ils incarnent l'excellence et la discrétion, garantissant un séjour sans faille où le moindre détail est géré avec une précision et une attention inégalées.",
          img: ExtrasFour,
        },
      ],
      imageBanner: {
        title:
          "Découvrez nos services extras d'exception, offrez-vous une expérience de service sans pareil, et créez des souvenirs inoubliables.",
        img: ExtrasLastBanner,
      },
    },
  },
];
