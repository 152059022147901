import React from "react";
import styled from "styled-components";

const DarkFilterImage = ({ src, alt, children, position, filterColor, filterOpacity, imageHeight, minHeight, backdropFilterImage }) => {
  // Fournir des valeurs par défaut si les props ne sont pas définies
  const positionDef = position ?? "relative";
  const filterColorDef = filterColor ?? "rgba(0, 0, 0"; // Couleur par défaut sans l'opacité
  const filterOpacityDef = filterOpacity ?? 0.6; // Opacité par défaut
  const imageHeightDef = imageHeight ?? "100vh"; // Hauteur par défaut
  const backdropFilterImageDef =  backdropFilterImage ?? "none"


  return (
    <ImageWrapper position={positionDef} minHeight={minHeight}>
      <Image src={src} alt={alt} height={imageHeightDef} minHeight={minHeight} />
      <Overlay backdropFilter={backdropFilterImageDef} color={filterColorDef} opacity={filterOpacityDef} />
      <Content>{children}</Content>
    </ImageWrapper>
  );
};

const ImageWrapper = styled.div`
  position: ${({ position }) => position};
  display: block;
  width: 100%;
  min-height: ${(props) => props.minHeight ?? 0};
  `;

const Image = styled.img`
  display: block;
  width: 100%;
  height: ${({ height }) => height};
  object-fit: cover;
  min-height: ${(props) => props.minHeight ?? 0};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ color, opacity }) => `${color}, ${opacity})`}; // Utilisez la couleur et l'opacité
  backdrop-filter: ${(props) => props.backdropFilter};
  
  pointer-events: none;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
`;

export default DarkFilterImage;
