import styled from "styled-components";
import ServicePresentation from "./ServicePresentation";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useRef, useState } from "react";
import Bullets from "../basic/bullets";
import MenuList from "./MenuList";

const ServicesPresentationList = ({ list, menu }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef();
  // Configuration de react-slick pour le fade
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    // accessibility: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
  };

  // Fonction pour changer de slide à partir du menu
  const changeSlide = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  return (
    <Container id="service">
      <Slider ref={sliderRef} {...settings}>
        {list.map((element, index) => (
          <div key={index}>
            <ServicePresentation
              active={activeIndex === index}
              data={element}
              menu={menu}
            />
          </div>
        ))}
      </Slider>
      <MenuList activeIndex={activeIndex} changeSlide={changeSlide} menu={menu} />
      <Bullets activeIndex={activeIndex} totalSlides={list.length} sliderRef={sliderRef} />
    </Container>
  );
};

const Container = styled.div`
  /* Mettez vos styles ici */
  position: relative;
`;

export default ServicesPresentationList;
