import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg"; // Assurez-vous que le chemin est correct
import { Column, Row, SizedBox } from "../basic/box";
import { BaseLink, BaseText, SubTitle } from "../basic/text";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CiMail } from "react-icons/ci";

const Footer = () => {
  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  return (
    <FooterContainer>
      <StyledLogo />
      <SectionList>
        <Section>
          <TitleColumn secondary>Informations</TitleColumn>
          <SizedBox height="20px" />
          <TextList gap="20px">
            <Text secondary>Hortensia Conciergerie</Text>
            <Text secondary>15 rue Camille Flammarion, 91630 Avrainville</Text>
          </TextList>
        </Section>
        <Section>
          <TitleColumn secondary>Services</TitleColumn>
          <SizedBox height="20px" />
          <TextList gap="10px">
            <Link
              onClick={() => handleClick("/services/voyage-deplacement")}
              secondary
            >
              Voyages & Déplacements
            </Link>
            <Link onClick={() => handleClick("/services/logement")} secondary>
              Logement
            </Link>
            <Link
              onClick={() => handleClick("/services/restauration")}
              secondary
            >
              Restauration
            </Link>
            <Link
              onClick={() => handleClick("/services/transport")}
              secondary
            >
              Transports
            </Link>
            <Link
              onClick={() => handleClick("/services/visites-excursions")}
              secondary
            >
              Visites & Excursions
            </Link>
            <Link onClick={() => handleClick("/services/bien-etre")} secondary>
              Bien-être
            </Link>
            <Link onClick={() => handleClick("/services/yatching")} secondary>
              Yatching
            </Link>
            <Link onClick={() => handleClick("/services/extras")} secondary>
              Services extras
            </Link>
          </TextList>
        </Section>
        <Section>
          <TitleColumn secondary>Nous contacter</TitleColumn>
          <SizedBox height="20px" />
          <TextList gap="10px">
            <Link href="https://api.whatsapp.com/send?phone=33659978813" secondary>
              <CustomFaWhatsapp /> WhatsApp
            </Link>
            <Link href="https://www.instagram.com/hortensia_conciergerie?igsh=MTZ6YmV0cGgwZXUyaA==" secondary>
              <CustomFaInstagram /> Instagram
            </Link>
            <Link href="mailto:contact@hortensia-conciergerie.com" secondary>
              <CustomCiMail /> Mail
            </Link>
          </TextList>
        </Section>
      </SectionList>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent12};
`;

const TitleColumn = styled(SubTitle)`
  margin: 0;
`;

const CustomCiMail = styled(CiMail)`
  margin-right: 5px;
  font-size: clamp(1.2rem, 2.5vw, 1.4rem);
`;

const CustomFaWhatsapp = styled(FaWhatsapp)`
  /* color: white;
  background-color: #30ba44; */
  /* border-radius: 5px;
  padding: 2px; */
  margin-right: 5px;
  font-size: clamp(1.2rem, 2.5vw, 1.4rem);
`;
const CustomFaInstagram = styled(FaInstagram)`
  /* background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  border-radius: 5px;
  padding: 2px; */
  margin-right: 5px;
  font-size: clamp(1.2rem, 2.5vw, 1.4rem);
  /* color: white; */
`;

const SectionList = styled(Row)`
  display: flex;
  gap: 50px;
  @media screen and (max-width: 1060px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Section = styled(Column)`
  padding: 0px 50px;
  width: 220px;
  @media screen and (max-width: 1060px) {
    align-items: center;
    justify-content: center;
  }
`;

const StyledLogo = styled(Logo)`
  fill: ${({ theme }) => theme.colors.accent15};
  height: 35px;
  margin-bottom: 50px;
  &:hover {
    cursor: pointer;
  }
`;

const TextList = styled(Column)`
  gap: ${(props) => props.gap ?? "0px"};
  @media screen and (max-width: 1060px) {
    align-items: center;
    justify-content: center;
  }
`;

const Text = styled(BaseText)`
  margin: 0;
  @media screen and (max-width: 1060px) {
    text-align: center;
  }
`;

const Link = styled(BaseLink)`
  margin: 0;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media screen and (max-width: 1060px) {
    text-align: center;
  }
`;

export default Footer;
